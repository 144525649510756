<template>
  <div id="app">

    <!-- Loader Start -->
    <div v-if="(loading == true && !this.DBitems) ||$store.state.loading"
      class="d-flex flex-row justify-content-center loading_card">
      <!-- LOADER -->
      <div style="position: fixed;
    top: 40%;
    left: 40%;">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />

      </div>
    </div>
    <!-- Loader End -->

    <div>
      <header-component8></header-component8>
      

      <div >
       
        <router-view class="theme8" name="theme8"  />
        <!--</vue-page-transition>-->
      </div>



      <!--</vue-page-transition>-->

      <footer-component8></footer-component8>
      

      <div style="text-align:center; margin-top:100px" v-if="NotFound">
        <a href="https://etrolley.net/">
          <img style="max-width:250px" src="https://etrolley.net/websiteImages/logoAR.png" />
        </a>
        <h3 dir="ltr">Sorry, this store is not available !!</h3>
        <h3>عذراً هذا المتجر غير متاح !!</h3>
        <!--{{$t('NotFound')}}-->
      </div>

    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner, HollowDotsSpinner, CirclesToRhombusesSpinner } from "epic-spinners";



import HeaderComponent8 from "./theme8/components/header.vue";
import FooterComponent8 from "./theme8/components/footer.vue";


export default {
  components: {
    HeaderComponent8,
    FooterComponent8,
    HalfCircleSpinner,
    HollowDotsSpinner,
    CirclesToRhombusesSpinner

  },

  data: function () {
    return {
      logo: "",
      DBitems: null,
      loading: false,
      NotFound: false,

      title: null,
      subject: null,
      userimg: "",
      currentMessage: "",
      notify: false
    };
  },
  mounted() {
    //image with selected
    //this.loading=true;
    try {
      var obj = this.$messaging
        .getToken({
          vapidKey:
            "BDOMKCvpZbjN6yH3n3A3cNhV7_k7P6cucJmbgBJmiQVzSQUTHcaGaJQLfQic_WuY13FQfuANYrRhxlxYDqZcffA"
        })
        .then(currentToken => {
          if (currentToken) {
            this.$store.state.fsm_token = currentToken;
            console.log("client token", currentToken);
            try {
              fetch(
                `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/${this.$store.storeName}`,
                {
                  method: "POST",
                  headers: new Headers({
                    Authorization: `key=AAAArMCrdjc:APA91bGmp4VqB3d7cOAl4zEvy5dflbuGgLc0XkBRiDr-phafDn3e0ki9VmJiwY4aklDADxMDNZKseZGtkmfHXfAV3AaxfB8QxEkmFtdrDo8owdKgQjtqdzjuiD_bx8_OZq6vS7KOoSkw`
                  })
                }
              )
                .then(response => {
                  if (response.status < 200 || response.status >= 400) {
                    console.log("oops" + response.status, response);
                  }
                  console.log(`"${topic}" is subscribed`);
                })
                .catch(error => {
                  //console.error(error.result);
                });
            } catch (error) { }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ", err);
        });
      console.log(obj);
      this.receiveMessage();
    } catch (e) {
      console.log(e);
    }
  },
  created() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
   
    //////////////check if store is exist ////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName)
      .then(response => {
        if (response.data.code == "1") {

          this.DBitems = response.data.data;
          this.$store.state.unit = this.DBitems[0].unit_en;
          this.$store.state.unitAR = this.DBitems[0].unit_ar;
          this.$store.state.tax = this.DBitems[0].tax;
          this.$store.state.logo = this.DBitems[0].logo;
          this.$store.state.logo_footer = this.DBitems[0].logo_footer;
          this.$store.state.title_en = this.DBitems[0].title_en;
          this.$store.state.title_ar = this.DBitems[0].title_ar;
          this.$store.state.description_en = this.DBitems[0].description_en;
          this.$store.state.description_ar = this.DBitems[0].description_ar;
          this.$store.state.keyword_en = this.DBitems[0].keyword_en;
          this.$store.state.keyword_ar = this.DBitems[0].keyword_ar;

          if (this.$i18n.locale == 'ar') {
            this.$store.state.UnitPrice = this.$store.state.unitAR;
          }
          else {
            this.$store.state.UnitPrice = this.$store.state.unit;
          }
       

          this.$store.state.country_code = this.DBitems[0].country_code;
          this.$store.state.country_flag = this.DBitems[0].country_flag;
          this.$store.state.country_length = this.DBitems[0].country_length;
          this.$store.state.phone = this.DBitems[0].phone;
          this.$store.state.email = this.DBitems[0].email;
          this.$store.state.auth = this.DBitems[0].auth;
          this.$store.state.master_code = this.DBitems[0].master_code;
          this.$store.state.sms_type = this.DBitems[0].sms_type;
          this.$store.state.last_news = this.DBitems[0].last_news;
          this.loading = false;

          if (this.$store.state.last_news != 0) {
            let h2 = document.querySelector('body');

            h2.classList.add("ticker_news");
          }
          var r = document.querySelector(":root");
          r.style.setProperty("--primary-color", this.DBitems[0].primary_color);

          r.style.setProperty("--secondry-color", this.DBitems[0].secondry_color + " !important");
          document.getElementById("Headerimg").href = this.$store.state.logo_footer;
          if (this.$i18n.locale == 'ar') {
            document.title = this.DBitems[0].title_ar;
            document.getElementById("description_meta").content = this.DBitems[0].description_ar;
            document.getElementById("keywords_meta").content = this.DBitems[0].keyword_ar;
          }
          else {
            document.title = this.DBitems[0].title_en;
            document.getElementById("description_meta").content = this.DBitems[0].description_en;
            document.getElementById("keywords_meta").content = this.DBitems[0].keyword_en;
          }


          if (this.$localStorage.get(this.$store.storeName) != null) {
            console.log(
              "testttttttttt session : " +
              this.$store.storeName +
              " : " +
              this.$localStorage.get(this.$store.storeName)
            );

          }

        } else if (response.data.code == "-1") {
          this.NotFound = true;
        }
      });


  



  },
  updated() {
    if (!this.NotFound) {

    }
  },
  methods: {
    receiveMessage() {
      try {
        this.$messaging.onMessage(payload => {
          // debugger
          this.currentMessage = payload;
          let message;
          message =
            payload.notification.title + ":\n\n" + payload.notification.body;
          this.setNotificationBoxForm(
            //payload.data.shipmentWallNumber,
            payload.notification.title,
            payload.notification.body
          );
          //console.log(message);
          this.notify = true;
          this.$store.state.notificationUpdate = !this.$store.state
            .notificationUpdate;
          console.log(
            this.$store.state.notificationUpdate +
            "notificationUpdate  hiiiiiiiiiiii  from "
          );
          setTimeout(() => {
            this.notify = false;
          }, 10000);
        });
      } catch (e) {
        console.log(e);
      }
    },


  }

};


</script>
